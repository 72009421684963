/** @jsx jsx */

import { useEffect, useState } from 'react'
import { Box, Container, Flex, jsx, useColorMode } from 'theme-ui'
import {
  useCheckout,
  useTranslate,
  useUser,
} from '@chordcommerce/gatsby-theme-autonomy'
import { Link } from 'gatsby'
import BazaarVoiceOrderConfirmationScripts from '../BazaarVoiceOrderConfirmationScripts'
import ConfirmationLoading from './Containers/ConfirmationLoading'
import ConfirmationError from './Containers/ConfirmationError'
import ConfirmationContainer from './Containers/ConfirmationContainer'
import Text from '~/components/IntlComponents/Text'
import OrderConfirmationReferralPrompt from '~/components/Order/Confirmation/ReferralPrompt'
import OrderConfirmationSummary from '~/components/Order/Confirmation/Summary'
import OrderConfirmationCart from '~/components/Order/Confirmation/Cart'
import OrderConfirmationDetails from '~/components/Order/Confirmation/Details'
import OrderConfirmationContact from '~/components/Order/Confirmation/Contact'
import PreOrderNote from '~/components/Mini-Cart/PreOrderNote'
import SubscriptionInfoNote from '~/components/Account/Subscription/SubscriptionInfo'
import { localeFromPathname } from '~/utils/intl/localePrefix'
import useConfirmationAssets from '~/hooks/graphql/queries/intlQueries/use-confirmation-assets'
import { useTikTokHelper } from '~/components/TikTokHelper'
import { mntnConversionPixel } from '~/mtnPixel/mtn-conversion-pixel'
import FairingSurvey from '~/components/FairingSurvey'

const OrderConfirmationPage = ({ orderNumber, checkoutSessionId }) => {
  const { user, loadUserReferralIdentifier } = useUser()
  const { finalizeCheckout } = useCheckout()
  const [cart, setCart] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [colorMode, setColorMode] = useColorMode('CNF')
  const [hasSubscription, setHasSubscription] = useState(false)
  const [showShippingNote, setShowShippingNote] = useState(false)
  const { completePayment } = useTikTokHelper()
  const [referralPurl, setReferralPurl] = useState(null)

  const locale = localeFromPathname()

  const translate = useTranslate()

  useEffect(() => {
    setIsLoading(false)
    setColorMode('CNF')
    finalizeCheckout({ orderNumber, checkoutSessionId })
      .then(cart => {
        setCart(cart)
        if (
          cart.lineItems?.filter(item => item.giftCards?.length === 0)?.length >
          0
        ) {
          setShowShippingNote(true)
        }
        setHasSubscription(
          cart?.lineItems &&
            cart.lineItems.some(item => item.subscriptionLineItems.length > 0),
        )
        const contentItems = []
        cart?.lineItems &&
          cart.lineItems.forEach(item =>
            contentItems.push({
              content_id: item.variant.sku,
              quantity: item.quantity,
              price: item.price,
            }),
          )
      })
      .then(cart => {
        setIsLoading(false)
      })
      .catch(error => {
        setError(error)
      })
  }, [finalizeCheckout])

  useEffect(() => {
    if (cart?.email && !referralPurl) {
      loadUserReferralIdentifier({ email: cart.email })
        .then(({ purl }) => setReferralPurl(purl))
        .catch(e => console.warn('could not load user purl', e))
    }
  }, [loadUserReferralIdentifier, cart?.email, referralPurl])

  const confirmationAssets = useConfirmationAssets(locale)
  useEffect(() => {
    if (cart && cart.state === 'complete') {
      if (typeof window !== 'undefined' && window?.pintrk) {
        window.pintrk('track', 'checkout', {
          event_id: 'eventId0001',
          value: cart.total,
          order_quantity: 1,
          currency: 'USD',
          order_id: orderNumber,
          line_items: cart.lineItems.map(item => {
            return [
              {
                product_name: item.variant.name,
                product_variant: item.variant.sku,
                product_quantity: item.quantity,
              },
            ]
          }),
        })
      }
      ;(async () => {
        await completePayment(
          cart.id,
          cart.totalQuantity,
          cart.currency,
          cart.total,
        )
      })()

      mntnConversionPixel(cart.id, cart.itemTotal)
    }
  }, [cart])

  if (error) return <ConfirmationError error={error} />
  if (isLoading || !cart) return <ConfirmationLoading />

  return (
    <ConfirmationContainer cart={cart}>
      <BazaarVoiceOrderConfirmationScripts
        isLoading={isLoading}
        cart={cart}
        orderNumber={orderNumber}
      />
      <Container>
        <Flex
          sx={{
            flexDirection: ['column', null, 'row'],
          }}
        >
          <Flex
            sx={{
              width: ['100%', null, '60%'],
              flexDirection: 'column',
              paddingRight: [null, null, '0.75rem'],
              textAlign: ['center', null, 'left'],
            }}
          >
            <FairingSurvey cart={cart} />
            <OrderConfirmationDetails cart={cart} />
            {referralPurl && (
              <OrderConfirmationReferralPrompt purl={referralPurl} />
            )}

            <OrderConfirmationContact />
          </Flex>

          <Flex
            sx={{
              background: 'var(--theme-ui-colors-scndBg)',
              border: '3px solid var(--theme-ui-colors-secondary)',
              borderRadius: '8px',
              width: ['100%', null, '40%'],
              flexDirection: 'column',
              padding: '30px',
            }}
          >
            {hasSubscription && (
              <Box
                sx={{
                  borderRadius: '8px',
                  border: '2px #FFDD00 solid',
                  marginBottom: '20px',
                  color: 'inherit',
                  padding: '10px',
                  pt: '20px',
                  textAlign: 'center',
                }}
              >
                <Text>
                  {translate('confirmation.details.subscription_details')}
                  <Text
                    as={Link}
                    to={'/account/subscriptions'}
                    sx={{
                      textDecoration: 'underline',
                      ':hover': {
                        color: '#007300',
                      },
                    }}
                  >
                    {translate('account.page_title')}
                  </Text>
                  <Box sx={{ pt: '10px' }}>
                    {translate('confirmation.details.subscription_or')}
                  </Box>
                  <SubscriptionInfoNote
                    forwardSx={{
                      textAlign: 'center',
                      padding: 0,
                      border: 'none',
                      m: 0,
                    }}
                  />
                </Text>
              </Box>
            )}
            <Text
              sx={{
                width: '100%',
                borderBottom: '3px solid var(--theme-ui-colors-secondary)',
                fontFamily: 'Marby',
                textTransform: 'uppercase',
                paddingBottom: '30px',
                marginBottom: '30px',
              }}
            >
              {translate('cart.order_summary')}
            </Text>
            <OrderConfirmationCart cart={cart} />
            <OrderConfirmationSummary cart={cart} />
            {showShippingNote &&
              confirmationAssets &&
              confirmationAssets.delayedShippingNote && (
                <PreOrderNote note={confirmationAssets.delayedShippingNote} />
              )}
            {confirmationAssets && confirmationAssets.donationLegalCopy && (
              <Box
                dangerouslySetInnerHTML={{
                  __html:
                    confirmationAssets.donationLegalCopy.childMarkdownRemark
                      .html,
                }}
              ></Box>
            )}
          </Flex>
        </Flex>
      </Container>
    </ConfirmationContainer>
  )
}

export default OrderConfirmationPage
